<div class="modal fade show" tabindex="-1" role="dialog" style="display: block" aria-modal="true">
  <div class="modal-dialog shadow-lg modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Payment Form</h5>
        <button type="button" class="close" aria-label="Close" (click)="close.emit()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Select from existing cards -->
        <!-- TODO: Add component that retrieves existing cards from the database -->

        <!-- Give the option to add a new card or select an existing card -->
        <app-btrpay-stored-cards *ngIf="!showAddNewCard" [personId]="id_responsable.toString()"
          [id_concurso]="id_concurso.toString()" [entry]="entry"
          (cardSelected)="onCardSelected({cardholder: '', customer_vault_billing_id: $event.customer_vault_billing_id, customer_vault_id: $event.customer_vault_id})"
          (customerVaultId)="customerVaultId($event)"></app-btrpay-stored-cards>

        <!-- Add new card -->
        <button class="btn btn-success m-2" *ngIf="!showAddNewCard" (click)="addNewCard()">
          <i class="fas fa-plus"></i>
          Add a New Card
        </button>
        <div [hidden]="readyToPay || !showAddNewCard">
          <h4>Add New Card</h4>

          <div class="form-group" *ngIf="userPeople.length > 0">
            <select #cardSelect id="savedCards" class="form-control" (change)="onCardSelected({
                customer_vault_billing_id: cardSelect.value ? userPeople[cardSelect.selectedIndex - 1].customer_vault_billing_id : '',
                customer_vault_id: cardSelect.value ? userPeople[cardSelect.selectedIndex - 1].customer_vault_id : '',
                cardholder: cardSelect.value ? userPeople[cardSelect.selectedIndex - 1].cardholder : ''
              })">
              <option value="">Select a previous cardholder</option>
              <option *ngFor="let card of userPeople" [value]="card.id">
                {{ card.cardholder }}
              </option>
            </select>
          </div>

          <form class="p-2 rounded">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="first_name">Cardholder First Name</label>
                <input type="text" id="first_name" class="form-control Input" placeholder="First name" name="firstName"
                  required />
              </div>
              <div class="form-group col-md-6">
                <label for="last_name">Cardholder Last Name</label>
                <input type="text" id="last_name" class="form-control Input" placeholder="Last name" name="lastName"
                  required />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-9">
                <label for="address">Address</label>
                <input type="text" id="address" class="form-control Input" placeholder="Address" />
              </div>
              <div class="form-group col-md-3">
                <label for="zip">Zip Code</label>
                <input type="text" id="zip" class="form-control Input" placeholder="Zip Code" />
              </div>
            </div>

            <label for="ccnumber">Credit Card Number
              <i class="fas fa-check-circle" style="color: green" *ngIf="cardValidated"></i></label>
            <div id="ccnumber" placeholder="0000 0000 0000 0000"></div>
            <p class="text-danger" *ngIf="cardValidationMessage">
              {{ cardValidationMessage }}
            </p>
            <div class="row">
              <div class="col-8">
                <label for="ccexp">Expiration date&nbsp;
                  <i class="fas fa-check-circle" style="color: green" *ngIf="expValidated"></i></label><label
                  class="cvv-mm-yy">&nbsp;MM/YY</label>
                <div id="ccexp"></div>
                <p class="text-danger" *ngIf="expValidationMessage">
                  {{ expValidationMessage }}
                </p>
              </div>
              <div class="col-4">
                <label for="cvv">CVV/CVC
                  <i class="fas fa-check-circle" style="color: green" *ngIf="cvvValidated"></i></label>
                <div id="cvv"></div>
                <p class="text-danger" *ngIf="cvvValidationMessage">
                  {{ cvvValidationMessage }}
                </p>
              </div>
            </div>

            <button type="submit" [disabled]="
                isSubmitting || !cardValidated || !expValidated || !cvvValidated
              " id="payButton" class="btn btn-primary btn-block" [hidden]="readyToPay">
              <i class="fas fa-save"></i>
              <span class="pay-text"> Save Card</span>
              <i class="fas fa-spinner fa-spin spinner-icon" *ngIf="isSubmitting"></i>
            </button>
          </form>
        </div>
        <div *ngIf="readyToPay">
          <div class="alert alert-success" role="alert">
            <i class="fas fa-card"></i> Card Validated Successfully
          </div>
          <p class="mb-0 ps-3 pt-1 pb-3">
            Please click the button below to pay.
          </p>
          <button id="transactionButton" class="btn btn-success btn-block" (click)="processPayment()">
            <i class="fas fa-money-bill"></i>
            Pay {{ calculateTotal() }}
            <i class="fas fa-spinner fa-spin spinner-icon" *ngIf="isSubmitting"></i>
          </button>
          <p class="text-danger pt-2" *ngIf="paymentError">
            {{ paymentError }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>